import { useRouter } from 'next/router';
import { JSXElementConstructor, useEffect } from 'react';
import { useFirebaseAuthState } from './FirebaseProvider';

export function WithAuth<P>(Component: JSXElementConstructor<P>): JSXElementConstructor<P> {
  return function WithAuthWrapper(props: P) {
    const { loading, loggedIn } = useFirebaseAuthState();
    const { replace, asPath } = useRouter();

    useEffect(() => {
      if (loading) {
        return;
      }

      if (loggedIn) {
        return;
      }

      replace({ pathname: '/login', query: { 'return-to': asPath } });
    }, [loading, loggedIn, replace, asPath]);

    if (loading || !loggedIn) {
      return null;
    }

    return <Component {...(props as any)} />;
  };
}
