import * as React from 'react';
import cn from 'classnames';

type Tab = {
  readonly id: string;
  readonly label: React.ReactNode | ((active: boolean) => React.ReactNode);
};

type Props = {
  readonly tabs: ReadonlyArray<Tab>;
  readonly children: (tab: string) => React.ReactNode;
  readonly className?: string;
};

export function Tabs({ tabs, children, className }: Props) {
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);

  return (
    <div className={cn('flex flex-col', className)}>
      <ul className="flex-shrink-0 flex flex-row items-center">
        {tabs.map(tab => {
          const active = selectedTab.id === tab.id;

          return (
            <li
              key={tab.id}
              className={cn(
                'mx-1 md:mx-2',
                'px-2 md:px-4 py-1 md:py-1.5',
                'border rounded-lg',
                active ? ' border-gray-100 shadow-md' : 'border-white',
                'hover:border-gray-100 hover:bg-gray-50',
              )}
            >
              <button onClick={() => setSelectedTab(tab)}>
                {typeof tab.label === 'function' ? tab.label(active) : tab.label}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="flex-grow">{children(selectedTab.id)}</div>
    </div>
  );
}
