import { useMutation } from 'react-query';
import { useFirebaseAuth } from '../components/FirebaseProvider';

type AccountEmailUpdateParams = {
  readonly currentEmail: string;
  readonly newEmail: string;
  readonly password: string;
};

export function useAccountEmailUpdateMutation() {
  const { updateEmail } = useFirebaseAuth();

  return useMutation<unknown, unknown, AccountEmailUpdateParams>(async ({ currentEmail, newEmail, password }) => {
    return await updateEmail(currentEmail, newEmail, password);
  });
}
