import { useEffect, useRef, useState } from 'react';

/**
 * Tracks a boolean value and sets it to false after the given delay has passed or if the given value is changed to false.
 */
export function useAutoToggle(currentValue: boolean, delay: number = 1000) {
  const delayRef = useRef(delay);
  const [value, setValue] = useState(currentValue);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    if (!currentValue) {
      return;
    }

    setValue(currentValue);

    const timeout = setTimeout(() => setValue(false), delayRef.current);

    return () => clearTimeout(timeout);
  }, [currentValue]);

  return currentValue ? value : currentValue;
}
