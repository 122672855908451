import { useMutation } from 'react-query';
import { useApiClient } from './useApiClient';

type ProfileImageUploadParams = {
  /**
   * The ID of the entity associated with the picture.
   * If kind is recipe, then this should be a recipe ID,
   * if kind is ingredient, then this should be an ingredient ID,
   * and so on.
   *
   * If no ID is provided, a new ID will be generated by the backend.
   */
  readonly id?: string;

  /**
   * Indicates what kind of entity this picture is associated with.
   */
  readonly kind: 'recipe' | 'ingredient' | 'profile';

  /**
   * The binary data of the picture.
   */
  readonly imageData: Blob | File;
};

type ProfileImageUploadResult = {
  /**
   * The newly generated ID of the picture, or the ID that was provided when uploading the picture.
   */
  readonly id: string;

  /**
   * The URL to the uploaded picture, that can be used to display it.
   */
  readonly imageUrl: string;
};

export function usePictureUploadMutation() {
  const api = useApiClient();

  return useMutation<ProfileImageUploadResult, unknown, ProfileImageUploadParams>(async ({ id, kind, imageData }) => {
    const endpoint = typeof id === 'string' ? `/web/upload/${kind}/${id}` : `/web/upload/${kind}`;

    const res = await api.fetch(endpoint, {
      method: 'POST',
      query: {
        content_type: imageData.type,
      },
    });

    const { id: backendId, presigned_upload_url: presignedUploadUrl, image_url: imageUrl } = await res.json();

    if (typeof presignedUploadUrl !== 'string') {
      throw new Error('failed to fetch presigned upload URL');
    }

    await fetch(presignedUploadUrl, {
      method: 'PUT',
      headers: { 'Content-Type': imageData.type },
      body: imageData,
    });

    return {
      id: backendId,
      imageUrl,
    };
  });
}
