import { QueryClient, useInfiniteQuery } from 'react-query';
import { QueryKeys } from './QueryKeys';
import { apiFetch, ApiFetch, useApiClient } from './useApiClient';

const PAGE_SIZE = 12;

type AccountRecipe = {
  readonly id: string;
  readonly name: string;
  readonly image_url?: string;
};

export type AccountRecipesQueryResult = {
  readonly recipes: readonly AccountRecipe[];
};

export function useAccountRecipesQuery() {
  const { fetch } = useApiClient();

  return useInfiniteQuery(QueryKeys.accountRecipes(), ({ pageParam = 0 }) => fetchAccountRecipes(fetch, pageParam), {
    getNextPageParam: (lastPage, pages) => (lastPage.recipes.length >= PAGE_SIZE ? pages.length : undefined),
  });
}

export async function prefetchAccountRecipesQuery(client: QueryClient) {
  return await client.prefetchInfiniteQuery(QueryKeys.accountRecipes(), () => fetchAccountRecipes(apiFetch, 0));
}

async function fetchAccountRecipes(fetch: ApiFetch, page: number): Promise<AccountRecipesQueryResult> {
  const res = await fetch(`/web/account/recipes`, {
    query: {
      offset: page * PAGE_SIZE,
      limit: PAGE_SIZE,
    },
  });

  return res.json();
}
