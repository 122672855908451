import cn from 'classnames';
import { FirebaseError } from 'firebase/app';
import { User } from 'firebase/auth';
import Head from 'next/head';
import React, { ChangeEvent, forwardRef, HTMLAttributes, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAccountEmailUpdateMutation } from '../api/useAccountEmailUpdateMutation';
import { AccountQueryResult, useAccountQuery } from '../api/useAccountQuery';
import { useAccountRecipesQuery } from '../api/useAccountRecipesQuery';
import { useAccountUpdateMutation } from '../api/useAccountUpdateMutation';
import { usePictureUploadMutation } from '../api/usePictureUploadMutation';
import { Button, ButtonFileInput, ButtonLink } from '../components/Button';
import { useFirebaseAuthState } from '../components/FirebaseProvider';
import { Footer } from '../components/Footer';
import { TextField } from '../components/forms/Field';
import { Header } from '../components/Header';
import { Icon } from '../components/Icon';
import { RecipeCard } from '../components/RecipeCard';
import { Tabs } from '../components/Tabs';
import { TextLink } from '../components/TextLink';
import { WithAuth } from '../components/WithAuth';
import { useAutoToggle } from '../hooks/useAutoToggle';
import { AccountDeletionDialog } from '../components/AccountDeletionDialog';

export default WithAuth(function MyChoosyPage() {
  return (
    <>
      <Head>
        <title>Choosy - Mein Choosy</title>
      </Head>
      <Header />
      <main className="flex-grow mt-7.5 sm:mt-8.75 lg:mt-12 xl:mt-24">
        <Tabs
          className={cn(
            'mx-auto',
            'max-w-135 md:max-w-none',
            'px-5 sm:px-6 md:px-20 lg:px-0',
            'lg:pl-3.25 xl:pl-20 xl:pr-66.25',
            'pb-18 sm:pb-20 lg:pb-32 xl:pb-43.25',
            'lg:max-w-240 xl:max-w-313',
          )}
          tabs={[
            {
              id: 'recipes',
              label: (
                <div className="text-5 font-serif  text-choosy-gray">
                  <span className="hidden md:inline">Meine </span>Rezepte
                </div>
              ),
            },
            { id: 'profile', label: <div className="text-5 font-serif text-choosy-gray">Profil</div> },
            {
              id: 'account',
              label: (
                <div className="text-5 font-serif text-choosy-gray">
                  Account<span className="hidden md:inline"> Einstellungen</span>
                </div>
              ),
            },
          ]}
        >
          {tab => <ActiveTab tab={tab} />}
        </Tabs>
      </main>
      <Footer />
    </>
  );
});

type ActiveTabProps = {
  readonly tab: string;
};

function ActiveTab({ tab }: ActiveTabProps) {
  switch (tab) {
    case 'profile':
      return <TabProfile />;
    case 'account':
      return <TabAccount />;
    default:
      return <TabMyRecipes />;
  }
}

function TabMyRecipes() {
  const {
    data: recipesData,
    hasNextPage: hasMoreRecipes,
    isFetchingNextPage: fetchingMoreRecipes,
    fetchNextPage: fetchMoreRecipes,
  } = useAccountRecipesQuery();

  return (
    <Section className="mt-6 sm:mt-8 lg:mt-10 xl:mt-12 w-full">
      <div>
        <ButtonLink size="md" href="/recipes/new">
          Neues Rezept
        </ButtonLink>
      </div>
      {recipesData && recipesData.pages.length > 0 && recipesData.pages[0].recipes.length < 1 ? (
        <div className="mt-8">Du hast noch kein Rezept eingetragen</div>
      ) : null}
      <div className="mt-8 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
        {recipesData?.pages.map((page, i) => {
          return (
            <React.Fragment key={i}>
              {page.recipes.map(recipe => (
                <RecipeCard key={recipe.id} recipe={recipe} />
              ))}
            </React.Fragment>
          );
        })}
      </div>
      {hasMoreRecipes ? (
        <div className="flex justify-center mt-16">
          <Button type="button" onClick={() => fetchMoreRecipes()} disabled={fetchingMoreRecipes}>
            Mehr Rezepte laden
          </Button>
        </div>
      ) : null}
    </Section>
  );
}

function TabProfile() {
  const { data: accountData } = useAccountQuery();

  const {
    mutateAsync: updateAccount,
    isSuccess: isProfileUpdateSuccess,
    isLoading: isProfileUpdating,
    error: profileUpdateError,
  } = useAccountUpdateMutation();

  // we use this mutation a second time for updating the profile image,
  // so we do not mix the loading/success states with the actual profile update
  const { mutateAsync: updateAccountWithImage } = useAccountUpdateMutation();

  const {
    mutateAsync: uploadProfileImage,
    isSuccess: isProfileImageUpdateSuccess,
    isLoading: isProfileImageUpdating,
    error: profileImageUpdateError,
  } = usePictureUploadMutation();

  async function handleProfileUpdate({ profileName, username, website, instagram }: ProfileFormData) {
    await updateAccount({
      profileName,
      username,
      website,
      instagram,
      image: accountData?.profile.image_url,
    });
  }

  async function handleImageUpdate(image: File | Blob) {
    const { imageUrl } = await uploadProfileImage({ kind: 'profile', imageData: image });
    await updateAccountWithImage({
      profileName: accountData?.profile.profile_name,
      username: accountData?.profile.username,
      website: accountData?.profile.website_url,
      instagram: accountData?.profile.instagram_account,
      image: imageUrl,
    });
    return imageUrl;
  }

  return (
    <Section className="mt-6 sm:mt-8 lg:mt-10 xl:mt-12 w-full">
      {accountData ? (
        <ProfileImageForm
          profile={accountData}
          onImageChange={handleImageUpdate}
          success={isProfileImageUpdateSuccess}
          updating={isProfileImageUpdating}
          error={profileImageUpdateError}
        />
      ) : null}
      {accountData ? (
        <ProfileForm
          className="mt-3 sm:mt-4 lg:mt-5 xl:mt-6 w-full"
          profile={accountData}
          onSubmit={handleProfileUpdate}
          success={isProfileUpdateSuccess}
          updating={isProfileUpdating}
          error={profileUpdateError}
        />
      ) : null}
    </Section>
  );
}

function TabAccount() {
  const { user } = useFirebaseAuthState();

  const {
    mutateAsync: updateEmail,
    isSuccess: isAccountSettingsUpdateSuccess,
    isLoading: isAccountSettingsUpdating,
    error: accountSettingsUpdateError,
  } = useAccountEmailUpdateMutation();

  async function handleAccountSettingsUpdate({ email: newEmail, password }: AccountSettingsFormData) {
    if (!user) {
      throw new Error('not currently logged in');
    }

    const { email: currentEmail } = user;

    if (!currentEmail) {
      throw new Error('user has no email address');
    }

    await updateEmail({ currentEmail, newEmail: newEmail.trim(), password });
  }

  return (
    <>
      <Section className="mt-6 sm:mt-8 lg:mt-10 xl:mt-12 w-full">
        {user ? (
          <AccountSettingsForm
            user={user}
            onSubmit={handleAccountSettingsUpdate}
            success={isAccountSettingsUpdateSuccess}
            updating={isAccountSettingsUpdating}
            error={accountSettingsUpdateError}
          />
        ) : null}
      </Section>
      <Section className="mt-6 sm:mt-8 lg:mt-10 xl:mt-12 w-full">
        <AccountDeletionForm />
      </Section>
    </>
  );
}

const Section = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, children, ...props }, ref) => {
  return (
    <section {...props} ref={ref} className={cn(className)}>
      {children}
    </section>
  );
});

const SectionHeading = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => {
    return (
      <h2
        {...props}
        ref={ref}
        className={cn('font-serif font-black text-26px sm:text-30px lg:text-34px xl:text-40px', className)}
      />
    );
  },
);

type ProfileImageFormProps = {
  readonly profile: AccountQueryResult;
  readonly success: boolean;
  readonly updating: boolean;
  readonly error: any;
  readonly onImageChange: (image: File) => Promise<string>;
  readonly className?: string;
};

function ProfileImageForm({ profile, success, updating, error, onImageChange, className }: ProfileImageFormProps) {
  const [imageUrl, setImageUrl] = useState(profile.profile?.image_url);
  const showSuccessIndicator = useAutoToggle(success, 3000);

  async function handleImageChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    if (!file.type.startsWith('image/')) {
      throw new Error(`invalid file chosen: ${file.type}`);
    }

    const newImageUrl = await onImageChange(file);
    setImageUrl(newImageUrl);
  }

  return (
    <div>
      <div className={cn('flex items-center', className)}>
        <img src={imageUrl || '/images/avatar.svg'} className="w-full max-w-60 rounded-full" alt="" />
        <ButtonFileInput onChange={handleImageChange} size="md" className="ml-8">
          Bild hochladen
        </ButtonFileInput>
        {updating ? (
          <Icon id="refresh-cw" className="ml-4 animate-spin" />
        ) : showSuccessIndicator ? (
          <Icon id="check" className="ml-4" />
        ) : null}
      </div>
      {error ? (
        <ErrorMessage className="mt-8">
          Da ist etwas schief gegangen! Hast du ein PNG, JPG oder GIF hochgeladen? Sollte der Fehler weiterhin auftreten
          kontaktiere uns unter{' '}
          <TextLink href="mailto:kontakt@choosy.de?subject=Problem%20beim%20Profilbild%20hochladen">
            kontakt@choosy.de
          </TextLink>
        </ErrorMessage>
      ) : null}
    </div>
  );
}

type ProfileFormData = {
  readonly username: string;
  readonly profileName: string;
  readonly website: string;
  readonly instagram: string;
};

type ProfileFormProps = {
  readonly profile: AccountQueryResult;
  readonly onSubmit: (data: ProfileFormData) => Promise<unknown>;
  readonly success: boolean;
  readonly updating: boolean;
  readonly error: any;
  readonly className?: string;
};

function ProfileForm({ profile, onSubmit, success, updating, error, className }: ProfileFormProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError: setFormError,
  } = useForm<ProfileFormData>({});

  const showSuccessIndicator = useAutoToggle(success, 3000);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (error.name === 'UsernameTakenError') {
      setFormError('username', { type: 'validate' });
    }
  }, [error, setFormError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn(className)}>
      <div className="mt-8 flex flex-col justify-between w-full max-w-135">
        <TextField
          {...register('username')}
          id="username"
          className="w-full"
          type="text"
          placeholder="User-handle"
          defaultValue={profile.profile?.username || undefined}
          invalid={!!errors.username}
        />
        <div className="mt-8">
          <TextField
            {...register('profileName')}
            id="profileName"
            className="w-full"
            type="text"
            placeholder="Profile Name"
            defaultValue={profile.profile?.profile_name || undefined}
            invalid={!!errors.profileName}
          />
        </div>
        <div className="mt-8">
          <TextField
            {...register('website')}
            id="website"
            className="w-full"
            type="url"
            placeholder="Website"
            defaultValue={profile.profile?.website_url || undefined}
            invalid={!!errors.website}
          />
        </div>
        <div className="mt-8">
          <TextField
            {...register('instagram')}
            id="instagram"
            className="w-full"
            type="text"
            placeholder="Instagram Account"
            defaultValue={profile.profile?.instagram_account || undefined}
            invalid={!!errors.instagram}
          />
        </div>
        <div className="mt-8 w-full flex items-center justify-start">
          <Button type="submit" size="md" disabled={updating}>
            Profil aktualisieren
          </Button>
          {updating ? (
            <Icon id="refresh-cw" className="ml-4 animate-spin" />
          ) : showSuccessIndicator ? (
            <Icon id="check" className="ml-4" />
          ) : null}
        </div>
        {error ? (
          error.name === 'UsernameTakenError' ? (
            <ErrorMessage className="mt-8">
              Der gewählte Benutzername ist bereits vergeben, bitte wähle einen anderen Benutzernamen.
            </ErrorMessage>
          ) : (
            <ErrorMessage className="mt-8">
              Da ist etwas schief gegangen! Überprüfe deine Eingaben und versuche es erneut. Sollte der Fehler weiterhin
              auftreten kontaktiere uns unter{' '}
              <TextLink href="mailto:kontakt@choosy.de?subject=Problem%20beim%20Account%20aktualisieren">
                kontakt@choosy.de
              </TextLink>
            </ErrorMessage>
          )
        ) : null}
      </div>
    </form>
  );
}

type AccountSettingsFormData = {
  readonly email: string;
  readonly password: string;
};

type AccountSettingsFormProps = {
  readonly user: User;
  readonly onSubmit: (data: AccountSettingsFormData) => Promise<unknown>;
  readonly success: boolean;
  readonly updating: boolean;
  readonly error: any;
  readonly className?: string;
};

function AccountSettingsForm({ user, onSubmit, success, updating, error, className }: AccountSettingsFormProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AccountSettingsFormData>({});

  const showSuccessIndicator = useAutoToggle(success, 3000);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn('flex flex-col md:flex-row items-center', className)}>
      <div className="flex flex-col justify-between w-full max-w-135">
        <div>
          <TextField
            {...register('email')}
            id="email"
            className="w-full"
            type="email"
            placeholder="E-Mail"
            defaultValue={user.email ?? undefined}
            invalid={!!errors.email}
          />
        </div>
        <div className="mt-8">
          <TextField
            {...register('password', { required: true })}
            id="password"
            className="w-full"
            type="password"
            placeholder="Aktuelles Passwort zur Bestätigung"
            invalid={!!errors.password}
          />
        </div>
        <div className="mt-8 flex items-center justify-start">
          <Button type="submit" size="md">
            Account aktualisieren
          </Button>
          {updating ? (
            <Icon id="refresh-cw" className="ml-4 animate-spin" />
          ) : showSuccessIndicator ? (
            <Icon id="check" className="ml-4" />
          ) : null}
        </div>
        <AccountSettingsFormError error={error} />
      </div>
    </form>
  );
}

type AccountSettingsFormErrorProps = {
  readonly error: any;
};

function AccountSettingsFormError({ error }: AccountSettingsFormErrorProps) {
  if (!error) {
    return null;
  }

  const code = error instanceof FirebaseError ? error.code : 'unknown';

  if (code === 'auth/email-already-in-use') {
    return (
      <ErrorMessage className="mt-8">
        Die angegebene E-Mail Adresse wird bereits für einen anderen Account verwendet.
      </ErrorMessage>
    );
  }

  if (code === 'auth/invalid-email') {
    return <ErrorMessage className="mt-8">Die angegebene E-Mail Adresse ist ungültig.</ErrorMessage>;
  }

  if (code === 'auth/wrong-password') {
    return (
      <ErrorMessage className="mt-8">
        Das angegebene Passwort ist falsch. Überprüfe dein Passwort und versuche es erneut. Sollte der Fehler weiterhin
        auftreten kontaktiere uns unter{' '}
        <TextLink href="mailto:kontakt@choosy.de?subject=Problem%20beim%20Account%20aktualisieren">
          kontakt@choosy.de
        </TextLink>
      </ErrorMessage>
    );
  }

  return (
    <ErrorMessage className="mt-8">
      Da ist etwas schief gegangen! Überprüfe dein Passwort und versuche es erneut. Sollte der Fehler weiterhin
      auftreten kontaktiere uns unter{' '}
      <TextLink href="mailto:kontakt@choosy.de?subject=Problem%20beim%20Account%20aktualisieren">
        kontakt@choosy.de
      </TextLink>
    </ErrorMessage>
  );
}

const ErrorMessage = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={cn('text-red-400 text-sm', className)} />
));

type AccountDeletionFormProps = {
  readonly className?: string;
};

function AccountDeletionForm({ className }: AccountDeletionFormProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className={cn('flex flex-col md:flex-row items-center', className)}>
      <div className="flex flex-col justify-between w-full max-w-135">
        <div className="mt-8 flex items-center justify-start">
          <Button type="submit" size="md" theme="danger" onClick={() => setDialogOpen(true)}>
            Account löschen
          </Button>
          <AccountDeletionDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
        </div>
      </div>
    </div>
  );
}
