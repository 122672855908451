export const QueryKeys = {
  account: () => ['account'],
  accountRecipes: () => ['account', 'recipes'],
  profile: (firebaseUid: string | undefined, params: Record<string, any> = {}) => ['profile', firebaseUid, params],
  profileByUsername: (username: string | undefined, params: Record<string, any> = {}) => ['profile', username, params],
  profileRecipesByUsername: (username: string | undefined, params: Record<string, any> = {}) => [
    'profile',
    username,
    params,
    'recipes',
  ],
};
