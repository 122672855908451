import { forwardRef, HTMLAttributes } from 'react';
import cn from 'classnames';
import * as React from 'react';

type Props = HTMLAttributes<HTMLDivElement> & {
  readonly contentClassName?: string;
};

export const Square = forwardRef<HTMLDivElement, Props>(({ className, contentClassName, children, ...props }, ref) => {
  return (
    <div {...props} ref={ref} className={cn('relative', 'w-full', className)}>
      {/* Hack for making the parent element a perfect square see https://spin.atomicobject.com/2015/07/14/css-responsive-square/ */}
      <div className="h-0 pb-full" />
      <div className={cn('absolute top-0 left-0 w-full h-full', contentClassName)}>{children}</div>
    </div>
  );
});
