import { forwardRef, InputHTMLAttributes, ReactNode, TextareaHTMLAttributes } from 'react';
import cn from 'classnames';

type Props = {
  readonly label?: ReactNode;
  readonly htmlFor?: string;
  readonly className?: string;
  readonly children?: ReactNode;
};

type ValidationProps = {
  readonly invalid?: boolean;
};

function OutlinedField({ label, htmlFor, className, children, invalid }: Props & ValidationProps) {
  return (
    <div
      className={cn(
        'relative',
        'py-3.75 sm:py-4.5 lg:py-3.75 xl:py-4.5',
        'px-4 sm:px-4 lg:px-4 xl:px-4',
        'border',
        invalid ? 'border-red-400' : 'border-choosy-gray-lightest',
        'rounded-2',
        'focus-within:border-choosy-green-dark',
        className,
      )}
    >
      {children}
      <label
        htmlFor={htmlFor}
        className={cn(
          'absolute',
          'flex items-center sibling-placeholder-shown:items-baseline sibling-focus:items-center',
          'h-4.5 sm:h-5 lg:h-4.5 xl:h-5',
          '-top-2.25 sm:-top-2.5 lg:-top-2.25 xl:-top-2.5',
          'sibling-placeholder-shown:top-3.75 sm:sibling-placeholder-shown:top-4.5 lg:sibling-placeholder-shown:top-3.75 xl:sibling-placeholder-shown:top-4.5',
          'sibling-focus:-top-2.25 sm:sibling-focus:-top-2.5 lg:sibling-focus:-top-2.25 xl:sibling-focus:-top-2.5',
          'left-4 sibling-placeholder-shown:left-3 sibling-focus:left-4',
          'text-xs',
          'sibling-placeholder-shown:text-16px sm:sibling-placeholder-shown:text-18px lg:sibling-placeholder-shown:text-16px xl:sibling-placeholder-shown:text-20px',
          'sibling-focus:text-xs sm:sibling-focus:text-xs lg:sibling-focus:text-xs xl:sibling-focus:text-xs',
          'text-choosy-gray-lightest',
          'bg-gradient-text-field-label sibling-placeholder-shown:bg-none sibling-focus:bg-gradient-text-field-label',
          'transition-all',
          'cursor-text',
          'pointer-events-none',
          'px-1',
        )}
      >
        {label}
      </label>
    </div>
  );
}

export const TextField = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & ValidationProps>(
  ({ id, className, placeholder, invalid, ...props }, ref) => (
    <OutlinedField label={placeholder} htmlFor={id} className={className} invalid={invalid}>
      <input
        {...props}
        ref={ref}
        id={id}
        placeholder={placeholder}
        className={cn('w-full', 'placeholder-transparent', 'outline-none')}
      />
    </OutlinedField>
  ),
);

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextareaHTMLAttributes<HTMLTextAreaElement> & ValidationProps
>(({ id, className, placeholder, invalid, ...props }, ref) => (
  <OutlinedField label={placeholder} htmlFor={id} className={className} invalid={invalid}>
    <textarea
      {...props}
      ref={ref}
      id={id}
      placeholder={placeholder}
      className={cn('w-full', 'resize-none', 'placeholder-transparent', 'outline-none')}
    />
  </OutlinedField>
));
