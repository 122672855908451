import { useQuery } from 'react-query';
import { QueryKeys } from './QueryKeys';
import { useApiClient } from './useApiClient';

export type AccountQueryResult = {
  readonly profile: {
    readonly username?: string | null;
    readonly profile_name?: string | null;
    readonly website_url?: string | null;
    readonly instagram_account?: string | null;
    readonly image_url?: string | null;
    readonly recipes: readonly any[];
  };
};

export function useAccountQuery() {
  const { fetch } = useApiClient();
  return useQuery<AccountQueryResult>(QueryKeys.account(), async () => {
    const res = await fetch('/web/account');
    return res.json();
  });
}
