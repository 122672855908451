import { useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from './QueryKeys';
import { AccountQueryResult } from './useAccountQuery';
import { useApiClient } from './useApiClient';

type AccountUpdateParams = {
  readonly profileName?: string | null;
  readonly username?: string | null;
  readonly website?: string | null;
  readonly instagram?: string | null;
  readonly image?: string | null;
};

export class UsernameTakenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UsernameTakenError';
  }
}

export function useAccountUpdateMutation() {
  const { fetch } = useApiClient();
  const client = useQueryClient();

  return useMutation<unknown, unknown, AccountUpdateParams>(
    async ({ profileName, username, website, instagram, image }) => {
      const res = await fetch('/web/account', {
        method: 'PUT',
        autofail: false,
        json: {
          account: {
            profile_name: profileName || null,
            // FIXME: the server should accept an undefined/null username
            username: username || '',
            website_url: website || null,
            instagram_account: instagram || null,
            image_url: image || null,
          },
        },
      });

      if (res.status === 409) {
        throw new UsernameTakenError(`username '${username}' has already been taken`);
      }

      if (!res.ok) {
        throw new Error('failed to update account');
      }
    },
    {
      onSuccess: (_, params) => {
        for (const [key, data] of client.getQueriesData<AccountQueryResult>(QueryKeys.account())) {
          const newData: AccountQueryResult = {
            ...data,
            profile: {
              ...data.profile,
              profile_name: params.profileName,
              username: params.username,
              website_url: params.website,
              instagram_account: params.instagram,
              image_url: params.image,
            },
          };

          client.setQueryData(key, newData);
        }
      },
    },
  );
}
