type Props = {
  readonly level: 'low' | 'medium' | 'high';
  readonly className?: string;
};

export function PriceLevel({ level, className }: Props) {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 9.44922C0 8.89693 0.447715 8.44922 1 8.44922H4C4.55229 8.44922 5 8.89693 5 9.44922V13.1492C5 13.7015 4.55228 14.1492 4 14.1492H1C0.447715 14.1492 0 13.7015 0 13.1492V9.44922Z"
        fill="#969696"
        stroke="#969696"
        strokeWidth="0.5"
      />
      <path
        d="M6.5 5.64941C6.5 5.09713 6.94772 4.64941 7.5 4.64941H10.5C11.0523 4.64941 11.5 5.09713 11.5 5.64941V13.1494C11.5 13.7017 11.0523 14.1494 10.5 14.1494H7.5C6.94772 14.1494 6.5 13.7017 6.5 13.1494V5.64941Z"
        fill={level === 'medium' || level === 'high' ? '#969696' : '#737575'}
        fillOpacity={level === 'medium' || level === 'high' ? '1' : '0'}
        stroke="#969696"
        strokeWidth="0.5"
      />
      <path
        d="M13.25 1.84961C13.25 1.4354 13.5858 1.09961 14 1.09961H17C17.4142 1.09961 17.75 1.4354 17.75 1.84961V13.1496C17.75 13.5638 17.4142 13.8996 17 13.8996H14C13.5858 13.8996 13.25 13.5638 13.25 13.1496V1.84961Z"
        fill={level === 'high' ? '#969696' : '#737575'}
        fillOpacity={level === 'high' ? '1' : '0'}
        stroke="#969696"
        strokeWidth="0.5"
      />
    </svg>
  );
}
