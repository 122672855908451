import cn from 'classnames';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from './Button';
import { useFirebaseAuth, useFirebaseAuthState } from './FirebaseProvider';
import { Modal } from './Modal';
import { TextField } from './forms/Field';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
};

export function AccountDeletionDialog({ open, onClose }: Props) {
  const { reauthenticateWithPassword, reauthenticateWithApple, reauthenticateWithGoogle, deleteAccount } =
    useFirebaseAuth();
  const { user } = useFirebaseAuthState();

  const [reauthenticated, setReauthenticated] = useState(false);

  async function handleReauthenticateWithApple() {
    try {
      await reauthenticateWithApple();
      setReauthenticated(true);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleReauthenticateWithGoogle() {
    try {
      await reauthenticateWithGoogle();
      setReauthenticated(true);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleReauthenticateWithPassword(password: string) {
    try {
      await reauthenticateWithPassword(password);
      setReauthenticated(true);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleConfirmDeletion() {
    try {
      await deleteAccount();
      onClose();
    } catch (err) {
      console.error(err);
    }
  }

  function handleClose() {
    onClose();
    setReauthenticated(false);
  }

  if (!user) {
    return null;
  }

  const hasPasswordAuth = user.providerData.some(provider => provider.providerId === 'password');
  const hasGoogleAuth = user.providerData.some(provider => provider.providerId === 'google.com');
  const hasAppleAuth = user.providerData.some(provider => provider.providerId === 'apple.com');

  return (
    <Modal open={open} onClose={handleClose} title="Account Löschen">
      {!reauthenticated ? (
        <>
          <p className="mt-2 mb-2">
            Zum löschen bestätige bitte zuerst deine Identität indem du dich erneut anmeldest.
          </p>
          {hasAppleAuth ? (
            <div className="mt-6">
              <Button size="md" theme="apple" onClick={handleReauthenticateWithApple} className="w-full">
                <img src="/images/apple-logo-white.svg" alt="" className="h-4 mr-2" />
                Mit Apple anmelden
              </Button>
            </div>
          ) : null}
          {hasGoogleAuth ? (
            <div className="mt-6">
              <Button size="md" theme="google" onClick={handleReauthenticateWithGoogle} className="w-full">
                <img src="/images/google-logo.svg" alt="" className="h-4 mr-2" />
                Mit Google anmelden
              </Button>
            </div>
          ) : null}
          {hasPasswordAuth ? (
            <div className="mt-4 pt-4 border-t border-gray-100">
              <PasswordForm onSubmit={handleReauthenticateWithPassword} />
            </div>
          ) : null}
        </>
      ) : (
        <>
          <p className="mt-2 mb-2">
            Möchtest du deinen Choosy Account wirklich löschen? Dabei gehen alle deine Daten verloren und können nicht
            wiederhergestellt werden.
          </p>
          <div className="flex items-center justify-between space-x-4">
            <Button type="button" size="md" theme="secondary-light" onClick={handleClose} className="flex-1">
              Abbrechen
            </Button>
            <Button type="button" size="md" theme="danger" onClick={handleConfirmDeletion} className="flex-1">
              Löschen
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}

type PasswordFormData = {
  readonly password: string;
};

type PasswordFormProps = {
  readonly onSubmit: (password: string) => unknown;
  readonly className?: string;
};

function PasswordForm({ onSubmit, className }: PasswordFormProps) {
  const { register, handleSubmit } = useForm<PasswordFormData>({});

  const doSubmit = useCallback((data: PasswordFormData) => onSubmit(data.password), [onSubmit]);

  return (
    <form onSubmit={handleSubmit(doSubmit)} className={cn('w-full flex flex-col items-stretch', className)}>
      <div>
        <TextField
          {...register('password', { required: true })}
          id="password"
          className="w-full"
          type="password"
          placeholder="Passwort"
        />
      </div>
      <div className="mt-2 flex items-center">
        <Button type="submit" size="md" className="w-full">
          Anmelden
        </Button>
      </div>
    </form>
  );
}
