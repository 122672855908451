import cn from 'classnames';
import React from 'react';
import { IKImage } from 'imagekitio-react';
import { ButtonLink } from './Button';
import { PriceLevel } from './PriceLevel';
import { Square } from './Square';

type Props = {
  readonly recipe: any;
  readonly editable?: boolean;
  readonly className?: string;
};

export function RecipeCard({ recipe, editable = true, className }: Props) {
  return (
    <Square
      className={cn(
        'w-full max-w-sm',
        'border border-gray-100',
        'shadow hover:shadow-lg',
        'rounded-3xl',
        'overflow-hidden',
        'transition-shadow',
        className,
      )}
      contentClassName="flex flex-col"
    >
      <a href={`/recipes/${recipe.slug}`} className="h-full flex flex-col justify-end">
        <div className="">
          {recipe.image_url ? (
            <IKImage src={recipe.image_url} transformation={[{ width: 450 }]} />
          ) : (
            <img src="/images/recipe-placeholder.webp" alt="" />
          )}
        </div>
        <div className="relative bg-white flex-grow flex flex-col justify-between p-2 border-t border-choosy-green-dark">
          <h4 className="font-serif text-6 text-center line-clamp-2">{recipe.name}</h4>
          <div className={cn('flex justify-center', 'text-12px', className)}>
            <div className="flex items-center">
              <img src="/images/icon-time.svg" alt="Dauer" className="w-5" />
              <span className="ml-1 lg:ml-2 pt-0.5">{recipe.time} min</span>
            </div>
            <div className="ml-6 flex items-center">
              <img src="/images/icon-calories.svg" alt="Kalorien" className="w-5" />
              <span className="ml-0.5 lg:ml-1 pt-0.5">{recipe.kilocalories} kcal</span>
            </div>
            <div className="ml-6 flex items-center">
              <img src="/images/icon-euro.svg" alt="Kosten" className="w-5" />
              <PriceLevel level={recipe.costs} className="ml-1 lg:ml-2" />
            </div>
          </div>
        </div>
      </a>
      {editable ? (
        <div className="absolute top-2 right-2">
          <ButtonLink href={`/recipes/${recipe.slug}/edit`} size="md" theme="secondary" icon="edit">
            Bearbeiten
          </ButtonLink>
        </div>
      ) : null}
    </Square>
  );

  /*
      <Button onClick={clickButton}>{buttonLabel}</Button>
      <RecipeCardButton
        label={buttonLabel}
        icon={buttonIcon}
        backgroundColor="#fff"
        color="#3d403f"
        fontWeight="600"
        margin="24px auto 58px auto"
        noButton={noButton}
        reverse
        iconColor="#13a583"
        onClick={clickButton}
      /> */
}
